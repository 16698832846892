<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                                ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <div style="border-bottom: inset;">
        <form class="ml-1 mr-1">
            <v-container style="display: flex;">
                <v-text-field v-model="inputDescripcion" style="max-width: 40%; margin-right: 10px;" dense outlined
                    label="Buscar por descripción"></v-text-field>

                <v-btn @click="dialogAgregar = true" style="max-width: 30%; margin-left: 2%;"
                 color="primary">Agregar nuevo motivo</v-btn>
            </v-container>
        </form>

        <v-data-table :headers="encabezadoTablaPrincipal" :items="listaPrincipal" :footer-props="footerProps"
            class="elevation-1 tablaPrincipal" fixed-header style="margin: 0px 14px 14px 14px" height="58vh"
            loading-text="Cargando...">

            <template v-slot:[`item.editar`]="{ item }">
                <v-btn @click="mostarInformacion(item)" icon>
                    <v-icon color="orange"> border_color </v-icon>
                </v-btn>
            </template>

            <template v-slot:[`item.activo`]="{ item }">
                <v-btn @click="ActivarMotivo(item)" icon>
                    <v-icon :color="item.eliminado === true ? 'error' : 'success'"> check_circle </v-icon>
                </v-btn>
            </template>

        </v-data-table>

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor" multi-line top :timeout="5000">
            {{ mensaje }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" text v-bind="attrs" @click="snackbarVisible = false">CERRAR</v-btn>
            </template>
        </v-snackbar>

        <v-dialog persistent max-width="35%" v-model="dialogAgregar">
            <form>
                <v-card>
                    <v-card-title style="background-color: #0D47A1; color: white">Agregar motivo</v-card-title>
                    <v-container class="inputs">
                        <v-autocomplete v-model="nuevoTipo" :items="opcionesMotivos"
                         dense outlined label="Elija el tipo">
                        </v-autocomplete>

                        <v-text-field v-model="nuevaDescripcion" dense outlined class="mt-2"
                        label="Ingrese la descripción"></v-text-field>
                    </v-container>

                    <v-container style="display: flex; justify-content: end; padding-top: 0;" class="pr-5">
                        <v-btn @click="cerrarDialog()" style="max-width: 22%;" class="mr-4"
                            color="error">cerrar</v-btn>
                        <v-btn @click="postAgregarNuevoMotivo()" color="green"
                            style="color:aliceblue; max-width:22%;">guardar</v-btn>
                    </v-container>
                </v-card>
            </form>
        </v-dialog>
        <v-dialog persistent max-width="35%" v-model="dialogEditar">
            <form>
                <v-card>
                    <v-card-title style="background-color: #0D47A1; color: white">Editar motivo</v-card-title>
                    <v-container class="inputs">
                        <v-autocomplete v-model="editTipo" :items="opcionesMotivos"
                         dense outlined label="Elija el tipo">
                        </v-autocomplete>

                        <v-text-field v-model="editDescripcion" dense outlined class="mt-2"
                        label="Ingrese la nueva descripción"></v-text-field>
                    </v-container>

                    <v-container style="display: flex; justify-content: end; padding-top: 0;" class="pr-5">
                        <v-btn @click="cerrarDialog()" style="max-width: 22%;" class="mr-4"
                            color="error">cerrar</v-btn>
                        <v-btn @click="editarInformacion()" color="green"
                            style="color:aliceblue; max-width:22%;">guardar</v-btn>
                    </v-container>
                </v-card>
            </form>
        </v-dialog>
    </div>
</template>

<script>

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
    data() {
        return {
            encabezadoTablaPrincipal: [
                { text: "TIPO MOTIVO", value: "tipoMotivo", sortable: false },
                { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
                { text: "EDITAR", value: "editar", sortable: false },
                { text: "ESTADO", value: "activo", sortable: false },
            ],
            footerProps: {
                showFirstLastPage: true,
                "show-current-page": true,
                'items-per-page-text': 'Ítems por página',
                'items-per-page-options': [5, 10, 20, 50,],
                'items-length': 100
            },
            opcionesMotivos:[
                {text: "Ubicación", value: "U"},
                {text: "Motivo", value: "M"},
                {text: "Devolución", value: "D"},
            ],
            listaPrincipal: [],
            respuestaExistente:[],
            dialogAgregar: false,
            dialogEditar: false,
            snackbarVisible: false,
            mensaje: "",
            snackbarColor: "",
            inputDescripcion: "",
            nuevoTipo: "",
            nuevaDescripcion:"",
            editTipo: "",
            editDescripcion: "",
            idReferenciaEditar: "",

        }
    },

    created() {
        this.getListaPrincipal();
    },

    methods: {


        getListaPrincipal() {
           let tipoMotivos = {
                U: "Ubicación",
                M: "Motivo",
                D: "Devolución"
            };
            this.$http.get("/msa-administration/motivos/listaPrincipal", {
                params: {
                    descripcion: this.inputDescripcion
                }
            }).then((result) => {
                console.log(result.data)
                this.listaPrincipal = result.data.map((item) => {
                    const { tipoMotivo, ...element } = item;
                      return {
                        ...element, 
                        tipoMotivo : tipoMotivos[tipoMotivo]?tipoMotivos[tipoMotivo]:"valor no encontrado"
                      }
                })
            })
        },

        getValidarExistente(tipoMotivo, Descripcion){
            return new Promise((resolve) => {
                this.$http.get("/msa-administration/motivos/validarExistente", {
                    params:{
                        tipoMotivo: tipoMotivo,
                        descripcion: Descripcion
                    }
                }).then((result) => {
                    this.respuestaExistente = result.data;
                    console.log(this.respuestaExistente)
                    resolve(true)
                })
            })
        },

        async postAgregarNuevoMotivo(){
            let regex = /^[0-9A-Za-zÁÉÍÓÚÑáéíóúñ\s]+$/;
            await this.getValidarExistente(this.nuevoTipo, this.nuevaDescripcion);
            if(this.nuevoTipo != null && this.nuevaDescripcion != ""){
                if(this.respuestaExistente?.length == 0){
                    if(regex.test(this.nuevaDescripcion)){
                        this.$http.post("/msa-administration/motivos/agregarMotivo", {
                            tipoMotivo: this.nuevoTipo,
                            descripcion: this.nuevaDescripcion
                        }).then(() => {
                            this.getListaPrincipal()
                            this.dialogAgregar = false;
                            this.snackbarVisible = true;
                            this.snackbarColor = "green";
                            this.mensaje = "Elemento agregado con éxito";
                        }).catch((error) => {
                            console.log(error)
                            this.snackbarVisible = true;
                            this.snackbarColor = "red";
                            this.mensaje = "Ocurrió un error inesperado";
                        })
                    }else{
                        this.snackbarVisible = true;
                        this.snackbarColor = "red";
                        this.mensaje = "La descripción solo puede contener números y letras";  
                    }
                }else{
                this.snackbarVisible = true;
                this.snackbarColor = "red";
                this.mensaje = "Ya existe una descripción asociada al motivo"; 
                }
            }else{
                this.snackbarVisible = true;
                this.snackbarColor = "red";
                this.mensaje = "Debe llenar todos los campos";
            }
            
        },

        mostarInformacion(item){
            console.log(item)
            this.dialogEditar = true;
            this.editDescripcion = item.descripcion;
            this.idReferenciaEditar = item.id;
            if(item.tipoMotivo == "Ubicación"){
                this.editTipo = "U"
            }
            if(item.tipoMotivo == "Motivo"){
                this.editTipo = "M"
            }
            if(item.tipoMotivo == "Devolución"){
                this.editTipo = "D"
            }
        },

        async editarInformacion(){
            console.log(this.editTipo, this.editDescripcion)
            await this.getValidarExistente(this.editTipo, this.editDescripcion);
            if(this.editTipo != null && this.editDescripcion != ""){
                if(this.respuestaExistente?.length == 0){
                    let regex = /^[0-9A-Za-zÁÉÍÓÚÑáéíóúñ\s]+$/
                    if(regex.test(this.editDescripcion)){
                        this.$http.put("/msa-administration/motivos/editarMotivo",{
                            id: this.idReferenciaEditar,
                            tipoMotivo: this.editTipo,
                            descripcion: this.editDescripcion
                        }).then(() => {
                            this.getListaPrincipal();
                            this.dialogEditar = false;
                            this.snackbarVisible = true;
                            this.snackbarColor = "green";
                            this.mensaje = "Elemento editado con éxito";
                        }).catch((error) => {
                            console.log(error);
                            this.snackbarVisible = true;
                            this.snackbarColor = "red";
                            this.mensaje = "Ocurrió un error inesperado";
                        })
                    }else{
                        this.snackbarVisible = true;
                        this.snackbarColor = "red";
                        this.mensaje = "La descripción solo puede contener números y letras";   
                    }
                }else{
                    this.snackbarVisible = true;
                    this.snackbarColor = "red";
                    this.mensaje = "Ya existe una descripción asociada al motivo";  
                }
            }else{
                    this.snackbarVisible = true;
                    this.snackbarColor = "red";
                    this.mensaje = "Debe llenar todos los campos";
            }
        },

        ActivarMotivo(item){
            this.$http.put("/msa-administration/motivos/activarMotivos", {
                    id: item.id
            }).then((result) => {
                console.log(result)
                console.log(item.id)
                this.getListaPrincipal();
            })
        },

        cerrarDialog(){
            this.dialogAgregar = false;
            this.dialogEditar = false;
            this.nuevoTipo = "";
            this.nuevaDescripcion = "";
            this.editTipo = "";
            this.editDescripcion = "";
        }

        
    },

    watch:{

        "inputDescripcion": debounce (function () {
        this.getListaPrincipal()
         }, 500),
    }
}
</script>

<style scoped>
::v-deep .v-messages {
    display: none;
}

::v-deep .v-text-field__details {
    display: none;
}

::v-deep .tablaPrincipal div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaPrincipal div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
</style>
